import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { getAssetUrl } from '../services/cdn'
import { Container as BlockContainer } from '../components/Blocks'
import { HeroVideo, CoreValues } from '../components/Sections'
import { Container, Row, Col } from 'reactstrap'

const boardOfDirectors = [
  {
    src: 'app/directors/Dir-Doc-Lito.png',
    name: 'Dr. Angelito B. Bagui',
    position: 'Chairperson of the Board',
    more: []
  },
  {
    src: 'app/directors/Dir-Eddie.png',
    name: 'Psupt Edgardo R. Dimaano (Ret) ',
    position: 'Vice-Chairperson of the Board',
    more: ['Chairperson, Education and Training Committee']
  },
  {
    src: 'app/directors/Dir-Pastor-Borigas.png',
    name: 'Ptr. Atty. Mickel M. Borigas',
    position: 'Director',
    more: [
      'Chairperson, Finance Oversight Committee'
    ]
  },
  {
    src: 'app/directors/Dir-Ruben.png',
    name: 'Engr. Ruben D. Celestial',
    position: 'Director',
    more: [
      'Chairperson, Cooperative Governance Committee'
    ]
  },
  {
    src: 'app/directors/Dir-Aurea.png',
    name: 'Ms. Aurea F. Faral',
    position: 'Director',
    more: [
      'Chairperson, Shared Services Oversight Committee'
    ]
  },
  {
    src: 'app/directors/Dir-Elsa.png',
    name: 'Ms. Elsa B. Geron',
    position: 'Director',
    more: [
      'Chairperson, Reach Oversight Committee'
    ]
  },
  {
    src: 'app/directors/Dir-Josie.jpg',
    name: 'Ms. Josie G. Manalo',
    position: 'Director',
    more: [
      'Chairperson, Gender and Development Committee'
    ]
  },
  {
    src: 'app/directors/Dir-Alona.jpg',
    name: 'Ms. Alona B. Panaligan',
    position: 'Director',
    more: [
      'Chairperson, Revenue Oversight Committee'
    ]
  },
  {
    src: 'app/directors/Dir-Ruby.png',
    name: 'Ms. Rubylyn O. Reyes',
    position: 'Director',
    more: [
      'Chairperson, Risk Oversight Committee',
      'Chairperson, ICS Special Committee',
    ]
  },
]

const IndexPage = () => (
  <Layout>
    <SEO title="Board Members" keywords={[`gatsby`, `application`, `react`]} />
    <HeroVideo id="main-hero" height={null} src="app/Video/anniversary.mp4" posterSrc={getAssetUrl('app/video-poster.png')} loop={true} autoplay muted>
    {/* <HeroVideo id="main-hero" height={null} x={50} y={50} src="app/Video/" posterSrc={getAssetUrl('app/ASEAN_Banner.jpg')} loop={true} autoplay muted> */}
      <div style={{ height: '350px'}}></div>
    </HeroVideo>
    <BlockContainer padding={5}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">SIDC BOARD OF DIRECTORS</h1>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer center={false}>
      <Container>
        <Row>
          {
            boardOfDirectors.map((director, key) => {
              let more = director.more.map((item,key) => <p key={key}>{ item }</p>)
              return (
                <Col md="6" sm="12" key={`${key}-${director.name}`}>
                  <div className="mb-4">
                    <Row>
                      <Col md="6" sm="12">
                        <img className="img-fluid d-block" src={getAssetUrl(director.src)} alt={director.name} />
                      </Col>
                      <Col md="6" sm="12">
                        <div>
                          <h4><b>{ director.name }</b></h4>
                          <p><i>{ director.position }</i></p>
                          { more }
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </BlockContainer>
    <CoreValues />
    {/* <BlockContainer padding={5} dark id="awards">
      <Container>
          <Row>
          <Col md="12">
              <div className="text-center">
                <h2 className="pb-4 text-dark">Awards</h2>
              </div>
          </Col>
          </Row>
          <Row>
          <Col sm={{order: 2}} md={{ size: 3, order: 1}} className="align-self-center text-md-right text-center">
              <h4 className="text-primary">One</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Two</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Three</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
          </Col>
          <Col sm={{ order: 1 }} md={{ size: 6, order: 2}} className="my-3 d-none d-md-block">
              <img className="img-fluid d-block pt-5" alt="SIDC BENEFITS" src={getAssetUrl('app/Awards.png')} />
          </Col>
          <Col sm={{order: 3}} md={{ size: 3, order: 3}} className="align-self-center text-md-left text-center">
              <h4 className="text-primary">One</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Two</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Three</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
          </Col>
          </Row>
      </Container>
    </BlockContainer> */}
    {/* <Parallax src="app/parallax.jpg">
      <Row>
        <Col sm="6" className="text-sm-left">
          <h1 className="text-primary">
              <b>Read about the <br />Organization Chart</b>
          </h1>
        </Col>
        <Col sm="6" className="text-sm-right d-flex align-items-end flex-column">
          <Link to="/organizational-chart" className="btn btn-primary mt-auto mb-auto"><i className="fa fa-download fa-fw"></i> Organization Chart</Link>
        </Col>
      </Row>
    </Parallax> */}
  </Layout>
)

export default IndexPage
